
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OrganizationModal from "@/components/modals/forms/settings/OrganizationModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
    OrganizationModal,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Assessor Name",
          key: "assessor_name",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Area of Expertise",
          key: "area_expertise",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          assessor_name: "Muhammad Asif Atick ",
          gender: "male",
          phone: "01714005887",
          email: "jossy81@gmail.com",
          area_expertise: "ASP.Net, C#, Database Design, ASP.Net MVC ",
          status: "Md. Yameen Hossain",
        },
        {
          id: 2,
          assessor_name: "Nazmus Saquibe",
          gender: "Male",
          phone: "01534666175",
          email: "nazmus.saquibe@chorki.com",
          area_expertise:
            "Programming Language: Java SE, C/C++, C# • Web Development: PHP5, HTML5, DHTML, XHTML, .NET, XML, JavaScript, Web Service, AJAX and JQuery.",
          status: "Md. Yameen Hossain ",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      lists: [],
      search: "",
      data: {},
      organizations: [],
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
